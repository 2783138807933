import React from "react";
import { FormattedMessage } from "react-intl";

export default props => (
  <div className="login-hint">
    <p>
      <strong>
        <FormattedMessage
          id="molecules.User.LoginForm.LoginHint.label"
          defaultMessage="Demo account:"
        />
      </strong>
    </p>
    <ul className="login-hint__credentials">
      <li>
        <FormattedMessage
          id="molecules.User.LoginForm.LoginHint.email"
          defaultMessage="Email:"
        />
        &nbsp;<span className="login-hint__selectable-value">
          roni_cost@example.com
        </span>
      </li>
      <li>
        <FormattedMessage
          id="molecules.User.LoginForm.LoginHint.password"
          defaultMessage="Password:"
        />
        &nbsp;<span className="login-hint__selectable-value">
          roni_cost3@example.com
        </span>
      </li>
    </ul>
  </div>
);
